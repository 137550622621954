import React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image1 from "../images/certificates/Appointment Letter.jpg";
import Image2 from "../images/certificates/Authorized Distributor.jpg";
import Image3 from "../images/certificates/ROHS.jpg";
import Image4 from "../images/certificates/户外EMC证书.jpg";
import Image5 from "../images/certificates/视觉EMC证书.jpg";
import Image6 from "../images/certificates/视觉安规证书.jpg";

const sertifikatPenghargaan = () => (
    <Layout>
        <SEO title="Tentang kami" />
        <section id={"tentang-kami"} className={"section-bg tentang-kami"}>
            <Container>
                <div className={"section-title"}>
                    <h2>Sertifikat dan Penghargaan</h2>
                    <p>
                        CV. Anugerah Terang merupakan rekanan andal yang telah
                        ditunjuk oleh Shenzhen LED Visual Photoelectric Co.,
                        Ltd. untuk memasarkan produk mereka di Indonesia. Produk
                        yang kami jual memenuhi standar{" "}
                        <strong>RoHS 2011/65/EU</strong> dan memenuhi direktif
                        Uni Eropa <strong>EMC 2014/30/EU</strong>
                    </p>
                    <br />
                    <Row>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image1}
                                    alt={"Surat Penunjukan Dealership"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        Surat Penunjukan Dealership
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image2}
                                    alt={"Sertifikat Distributor Resmi"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        Sertifikat Distributor Resmi
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image3}
                                    alt={"RoHS 2011/65/EU"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        RoHS 2011/65/EU Compliance
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"mt-5"}>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image4}
                                    alt={"EMC Directive 2014/30/EU"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        EMC Directive 2014/30/EU Compliance
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image5}
                                    alt={"EMC Directive 2014/30/EU"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        EMC Directive 2014/30/EU Compliance
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={Image6}
                                    alt={"LVD Directive 2014/35/EU"}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        LVD Directive 2014/35/EU Compliance
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    </Layout>
);

export default sertifikatPenghargaan;
